import { DestroyRef, Inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LOCAL_STORAGE } from '@core/injectors';
import { environment } from '@environments/environment';
import { AuthenticationService } from '@features/server-side-authentication';
import { filter, interval, map, startWith, Subscription, tap } from 'rxjs';

const DEFAULT_TIMEOUT_TIME = 10 * 60 * 1000; // 10 min

@Injectable()
export class IdleTokenService {
  public readonly STORAGE_KEY = 'tku';
  private readonly TIMER_INTERVAL = 10 * 1000; // 10s
  private readonly MAX_TIME_RESTORE = 30 * 60 * 1000;

  protected readonly timeoutTime = environment.restoreTokenTime || DEFAULT_TIMEOUT_TIME;
  private readonly timeoutTimeShift = Math.random() * 120 * 1000; // Random shift of timer in range of 2 min

  private dateTimeStart: Date | null = null;
  private timer = 0;

  private intervalRestoreToken!: Subscription;

  constructor(
    @Inject(LOCAL_STORAGE) private readonly storage: Storage,
    private readonly destroyRef: DestroyRef,
    private readonly authenticationService: AuthenticationService,
  ) {
    this.loadTimeFromStorage();
  }

  private updateTimeInStorage() {
    this.dateTimeStart = new Date();
    this.storage.setItem(this.STORAGE_KEY, this.dateTimeStart.toISOString());
  }

  public loadTimeFromStorage() {
    const activity: string | null = this.storage.getItem(this.STORAGE_KEY);

    if (activity) {
      this.dateTimeStart = new Date(activity);
      const currentTime: Date = new Date();
      const timeDiff = currentTime.getTime() - this.dateTimeStart.getTime();
      if (timeDiff >= this.timeoutTime + this.timeoutTimeShift && timeDiff < this.MAX_TIME_RESTORE) {
        this.restoreToken();
      }
    }
  }

  public restoreToken() {
    this.updateTimeInStorage();

    this.authenticationService.restoreToken().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  public startWatching() {
    this.updateTimeInStorage();

    // Restore token every X minutes
    // This is used to extend the JWT token life
    this.intervalRestoreToken = interval(this.TIMER_INTERVAL)
      .pipe(
        startWith(0),
        takeUntilDestroyed(this.destroyRef),
        tap(() => (this.timer += this.TIMER_INTERVAL)),
        map(() => new Date().getTime() - (this.dateTimeStart?.getTime() || 0)),
        filter((timeDiff) => timeDiff > this.timeoutTime + this.timeoutTimeShift && timeDiff < this.MAX_TIME_RESTORE),
      )
      .subscribe(() => {
        this.restoreToken();
        this.timer = 0;
      });
  }

  public stopWatching() {
    this.storage.removeItem(this.STORAGE_KEY);
    this.intervalRestoreToken?.unsubscribe();
  }
}
