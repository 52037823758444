@if (product(); as product) {
  <a [href]="product.url" target="_blank" class="product">
    <mat-card class="col productCard">
      <mat-card-content class="justify-content-center">
        <div class="d-flex">
          <mat-icon>{{ product.icon }}</mat-icon>
          <span class="px-2">{{ product.title }}</span>
        </div>
      </mat-card-content>
    </mat-card>
  </a>
}
