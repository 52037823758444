import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL } from '@core/injectors';
import { Observable } from 'rxjs';
import { OrganizationDetailsDTO, OrganizationItemDTO } from '../models';

@Injectable()
export class OrganizationsService {
  constructor(
    private readonly http: HttpClient,
    @Inject(API_BASE_URL) private readonly apyBaseUrl: string,
  ) {}

  getList(): Observable<OrganizationItemDTO[]> {
    return this.http.get<OrganizationItemDTO[]>(`${this.apyBaseUrl}/api/organizations-internal/`);
  }

  getData(id: string | null): Observable<OrganizationDetailsDTO> {
    return this.http.get<OrganizationDetailsDTO>(
      `${this.apyBaseUrl}/api/dashboard/` + (id ? `?as_customer=${id}` : ''),
    );
  }
}
