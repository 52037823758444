import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrl: './page404.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Page404Component {
  main_site_url = environment.postLogoutUri;
}
