import { Component, input } from '@angular/core';
import { ProductItemDTO } from '../../models';

@Component({
  selector: 'app-product-tile-organizations',
  templateUrl: './product-tile-organizations.component.html',
  styleUrl: './product-tile-organizations.component.scss',
})
export class ProductTileOrganizationsComponent {
  product = input.required<ProductItemDTO>();
}
