import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, input, OnInit, signal } from '@angular/core';
import { Ticket } from '@core/models';
import { UserService } from '@core/services';
import { environment } from '@environments/environment';
import { BehaviorSubject, catchError, EMPTY, finalize, tap, throwError } from 'rxjs';

@Component({
  selector: 'app-zendesk-tickets-table',
  templateUrl: './zendesk-tickets-table.component.html',
  styleUrls: ['./zendesk-tickets-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZendeskTicketsTableComponent implements OnInit {
  organizationId = input.required<number | null>();
  emptyListMessage = signal<string>('No data');
  isLoading = signal<boolean>(true);
  displayedColumns: string[] = ['number', 'title', 'status', 'lastUploaded'];
  dataSource = new BehaviorSubject<Ticket[]>([]); // Initialize
  zendeskAgent: boolean | undefined; // To store the value
  constructor(private readonly userService: UserService) {
    this.userService.getUserData().subscribe((userData) => {
      this.zendeskAgent = userData?.zendesk_agent;
    });
  }

  ngOnInit() {
    this.getUserTickets();
  }

  getUserTickets() {
    this.userService
      .getTickets(this.organizationId())
      .pipe(
        finalize(() => this.isLoading.set(false)),
        tap((tickets) => {
          this.emptyListMessage.set('No Issues Found');
          // limit amount of records to 7
          if (tickets.length) {
            tickets = tickets.slice(0, 7);
          }
          this.dataSource.next(tickets);
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status >= 400 && error.status < 500) {
            this.emptyListMessage.set('Unable to display data. Please try again later.');
            this.dataSource.next([]);
            return EMPTY; // Stop the observable chain
          } else {
            // Other error types
            console.error('An error occurred:', error);
            return throwError(() => error); // Re-throw for potential error handling elsewhere
          }
        }),
      )
      .subscribe();
  }

  seeMore() {
    window.location.href = environment.zendeskUrl + (this.zendeskAgent ? '/agent/' : '/hc/en-us/requests/organization');
  }

  protected readonly environment = environment;
}
