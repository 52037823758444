<div class="p-3 menu-navigation">
  <mat-form-field ngClass="input-normalize mat-field-fluid">
    <input type="search" matInput [formControl]="formFilter" placeholder="Search" />
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
  <div [formGroup]="form">
    @if (isLoading()) {
      <mat-progress-bar mode="query"></mat-progress-bar>
    }
    <mat-selection-list [hideSingleSelectionIndicator]="true" formControlName="organization" [multiple]="false">
      @for (organization of organizationList()?.filtered; track organization) {
        <mat-list-option class="menu-item" [value]="organization.pk">
          <span class="menu-item-content">
            {{ organization.name }}
          </span>
        </mat-list-option>
      } @empty {
        <mat-list-option disabled role="listitem">No organizations found</mat-list-option>
      }
    </mat-selection-list>
  </div>
</div>
