import { Component, input } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectorsStatusesType, DataSourcesStatusesType, ERROR_TOOLTIP_STATUSES } from '@core/models';
import { OrganizationConnectorItemDTO, OrganizationDataSourceItemDTO, OrganizationTableDataDTO } from '../../models';

@Component({
  selector: 'app-status-table-organizations',
  templateUrl: './status-table-organizations.component.html',
  styleUrl: './status-table-organizations.component.scss',
})
export class StatusTableOrganizationsComponent {
  errorStatuses!: (ConnectorsStatusesType | DataSourcesStatusesType)[];

  public data = input.required<OrganizationTableDataDTO, OrganizationTableDataDTO>({
    transform: (value: OrganizationTableDataDTO) => {
      this.errorStatuses =
        value.title === 'Connectors' ? ERROR_TOOLTIP_STATUSES.Connectors : ERROR_TOOLTIP_STATUSES.DataSources;
      return value;
    },
  });

  constructor(private router: Router) {}

  get statusColumnName(): string {
    return this.isConnectors() ? 'Status' : 'Connect Status';
  }

  errorStatusMessage(element: OrganizationConnectorItemDTO | OrganizationDataSourceItemDTO): string {
    return this.isConnectors()
      ? (element as OrganizationConnectorItemDTO).last_connector_run_error_message
      : (element as OrganizationDataSourceItemDTO).last_discovery_run_error_message;
  }

  isTooltipDisabled(status: string): boolean {
    const statusLocal = status.toLowerCase() as ConnectorsStatusesType | DataSourcesStatusesType;
    return !this.errorStatuses.includes(statusLocal);
  }

  private isConnectors(): boolean {
    return this.data().title === 'Connectors';
  }

  onSeeMore(url: string) {
    this.router.navigateByUrl(url);
  }
}
