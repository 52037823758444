import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, input, signal } from '@angular/core';
import { InfoMessagesService } from '@features/info-messages';
import { AccountService } from '@features/server-side-authentication';
import { catchError, finalize, Observable, of, tap } from 'rxjs';
import { OrganizationDetailsDTO, OrganizationTableDataDTO, ProductItemDTO } from '../../models';
import { OrganizationsService } from '../../services/organizations.service';

@Component({
  selector: 'app-page-organizations',
  templateUrl: './page-organizations.component.html',
  styleUrl: './page-organizations.component.scss',
})
export class PageOrganizationsComponent {
  isLoading = signal<boolean>(false);
  products = signal<ProductItemDTO[]>([]);
  statistics = signal<any>([]);
  selectedOrganization = input<number | null>(null);
  isDetailedView = input.required<boolean>();

  productsAdditionalInfo: any = {
    datahub: { title: 'Discover', icon: 'query_stats', order: 1 },
    airflow: { title: 'Schedule', icon: 'timer_10_alt_1', order: 2 },
    snowflake: { title: 'Snowflake', icon: 'ac_unit', order: 3 },
    airbyte: { title: 'Extract', icon: 'filter_alt', order: 4 },
    thoughtspot: { title: 'Thoughtspot', icon: 'no_flash', order: 5 },
    sftp: { title: 'SFTP', icon: 'private_connectivity', order: 6 },
    qvera: { title: 'Data Bridge', icon: 'flyover', order: 7 },
  };

  dataSources = signal<OrganizationTableDataDTO>({
    dataSource: [],
    title: 'Data Sources',
    columns: ['name', 'connect_status', 'approval_status'],
    url: '',
  });
  connectors = signal<OrganizationTableDataDTO>({
    dataSource: [],
    title: 'Connectors',
    columns: ['name', 'connect_status', 'last_connector_run_end'],
    url: '',
  });
  pipelines = signal<OrganizationTableDataDTO>({
    dataSource: [],
    title: 'Pipelines',
    columns: ['pipeline_name', 'status'],
    url: '',
  });

  organizationData$!: Observable<OrganizationDetailsDTO | null>;

  constructor(
    private readonly organizationsService: OrganizationsService,
    private readonly infoMessagesService: InfoMessagesService,
    private readonly accountService: AccountService,
  ) {}

  ngOnInit() {
    if (!this.isDetailedView()) {
      this.onChangeOrganization(this.selectedOrganization());
    }
  }

  setProductsData(productData: ProductItemDTO[] | undefined) {
    if (!productData) {
      return [];
    }
    return productData
      .map((productDataItem) => {
        const productAdditionslInfoItem = this.productsAdditionalInfo[productDataItem.link_type.toLocaleLowerCase()];

        if (productAdditionslInfoItem) {
          return { ...productAdditionslInfoItem, ...productDataItem };
        }

        return null;
      })
      .filter((item) => item !== null)
      .sort((a, b) => a.order - b.order);
  }

  onChangeOrganization(organizationId: any) {
    this.organizationData$ = this.organizationsService.getData(organizationId).pipe(
      tap((data) => {
        this.products.set(this.setProductsData(data.organization.product_links));

        this.statistics.set([
          {
            name: 'Data Sources',
            amount: data.counts.data_sources.all,
            fullyReady: data.counts.data_sources.fully_ready,
          },
          {
            name: 'Connectors',
            amount: data.counts.connectors.all,
            fullyReady: data.counts.connectors.fully_ready,
          },
          {
            name: 'Pipelines',
            amount: data.counts.pipelines.all,
          },
        ]);

        this.dataSources.update((obj) => ({
          ...obj,
          dataSource: data.data_sources,
          url: this.accountService.isInternal ? `organizations/${data.organization.pk}/data-sources` : 'data-sources',
        }));
        this.connectors.update((obj) => ({
          ...obj,
          dataSource: data.connectors,
          url: this.accountService.isInternal ? `organizations/${data.organization.pk}/connectors` : 'connectors',
        }));
        this.pipelines.update((obj) => ({
          ...obj,
          dataSource: data.pipelines,
          url: null,
        }));
      }),
      finalize(() => {
        this.isLoading.set(false);
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.NotFound)
          this.infoMessagesService.showError(`Organization with ID '${organizationId}' was not found!`);
        return of(null);
      }),
    );
  }
}
