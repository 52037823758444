<app-user-mimic-status-bar></app-user-mimic-status-bar>
<app-header [homeUrl]="['/organizations']"></app-header>
<mat-drawer-container class="content-container">
  <mat-drawer mode="side" opened [disableClose]="true">
    <app-menu-organizations
      [selectedOrganization]="organizationId()"
      (organization)="onChangeOrganization($event)"
    ></app-menu-organizations>
  </mat-drawer>
  <mat-drawer-content>
    <div class="container container-xl container-xxxl">
      <div class="row">
        <div class="col">
          <app-page-organizations
            [isDetailedView]="true"
            [selectedOrganization]="organizationId()"
          ></app-page-organizations>
        </div>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
