<mat-toolbar>
  <div class="container">
    <a class="navbar-brand" href="/">
      <img src="assets/main_logo_sm.png" alt="Diliko Portal" loading="lazy" class="brand-logo" />
    </a>
  </div>
  <div class="col"></div>
</mat-toolbar>
<div class="container">
  <error-page-content>
    <h2 class="error-main-text">Unknown error</h2>
    <div class="row">
      <div class="col-6">
        <a mat-stroked-button color="primary" class="error-nav-button" [href]="main_site_url">Corporate Site</a>
      </div>
      <div class="col-6">
        <a mat-flat-button color="primary" class="error-nav-button" href="/">Home Page</a>
      </div>
    </div>
  </error-page-content>
</div>
