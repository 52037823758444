import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MimicUserModule } from '@features/mimic-user';
import { SharedModule } from '@shared/shared.module';
import { HomeOrganizationComponent } from './components/home-organization/home-organization.component';
import { MenuOrganizationsComponent } from './components/menu-organizations/menu-organizations.component';
import { PageOrganizationComponent } from './components/page-organization/page-organization.component';
import { PageOrganizationsComponent } from './components/page-organizations/page-organizations.component';
import { ProductTileOrganizationsComponent } from './components/product-tile-organizations/product-tile-organizations.component';
import { StatisticsTileOrganizationsComponent } from './components/statistics-tile-organizations/statistics-tile-organizations.component';
import { StatusTableOrganizationsComponent } from './components/status-table-organizations/status-table-organizations.component';
import { ZendeskTicketsTableComponent } from './components/zendesk-tikets-table/zendesk-tickets-table.component';
import { OrganizationsRoutingModule } from './organizations-routing.module';
import { OrganizationsService } from './services/organizations.service';

@NgModule({
  declarations: [
    PageOrganizationsComponent,
    PageOrganizationComponent,
    StatisticsTileOrganizationsComponent,
    ProductTileOrganizationsComponent,
    StatusTableOrganizationsComponent,
    MenuOrganizationsComponent,
    HomeOrganizationComponent,
    ZendeskTicketsTableComponent,
  ],
  providers: [OrganizationsService],
  exports: [MenuOrganizationsComponent, PageOrganizationsComponent],
  imports: [CommonModule, SharedModule, MimicUserModule, OrganizationsRoutingModule, ReactiveFormsModule],
})
export class OrganizationsModule {}
