import { DOCUMENT } from '@angular/common';
import { Component, DestroyRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WINDOW } from '@core/injectors';
import { Observable, Subject, filter, fromEvent, map, tap } from 'rxjs';
import { IdleDialogActionType } from '../../models';
import { IdleTokenService } from '../../services/idle-token/idle-token.service';
import { IdleService } from '../../services/idle/idle.service';
import { IdleDialogComponent } from '../idle-dialog/idle-dialog.component';

@Component({
  selector: 'app-idle',
  templateUrl: './idle.component.html',
  styleUrl: './idle.component.scss',
})
export class IdleComponent implements OnInit, OnDestroy {
  idleTimer: Subject<number> = new Subject<number>();

  private dialogRef!: MatDialogRef<IdleDialogComponent, any> | null;

  constructor(
    @Inject(WINDOW) private readonly win: Window,
    @Inject(DOCUMENT) private readonly doc: Document,
    private readonly destroyRef: DestroyRef,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly idleService: IdleService,
    private readonly idleTokenService: IdleTokenService,
  ) {}

  ngOnInit() {
    this.idleService.startWatching();
    this.idleTokenService.startWatching();

    // Start watching when user idle is starting
    this.idleService
      .onTimerStart()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .pipe(
        filter((el) => el !== null),
        tap(() => this.showIdleModal()),
        map((count) => new Date(0, 0, 0).setSeconds(count || 0)),
      )
      .subscribe((timer) => this.idleTimer.next(timer));

    // Start watch when time is up
    this.idleService
      .onTimeout()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.closeDialog();
        this.onLogout();
      });

    this.listenStorageStateChanges();
  }

  ngOnDestroy() {
    this.idleService.stopWatching();
    this.idleTokenService.stopWatching();

    this.closeDialog();
  }

  public getStorageListener(): Observable<StorageEvent> {
    return fromEvent<StorageEvent>(this.win, 'storage');
  }

  listenStorageStateChanges() {
    this.getStorageListener()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event: StorageEvent) => {
        if (event.key === 'ac' && event.newValue === null) {
          this.onLogout();
        } else if (event.key === this.idleService.STORAGE_KEY) {
          this.idleService.resetTimer();
          if (event.newValue) {
            this.onDialogCallback(IdleDialogActionType.Continue);
          }
        } else if (event.key === this.idleTokenService.STORAGE_KEY) {
          this.idleTokenService.loadTimeFromStorage();
        }
      });
  }

  showIdleModal() {
    if (this.doc.hasFocus() && !this.dialogRef) {
      this.dialogRef = this.dialog.open(IdleDialogComponent, {
        width: '600px',
        maxWidth: '650px',
        disableClose: true,
        data: this.idleTimer.asObservable(),
      });
      this.dialogRef
        .afterClosed()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((state: IdleDialogActionType) => {
          this.onDialogCallback(state);
          this.closeDialog();
        });
    }
  }

  onDialogCallback(state: IdleDialogActionType) {
    if (state === IdleDialogActionType.Continue) {
      this.onContinue();
    } else if (state === IdleDialogActionType.Logout) {
      this.onLogout();
    }
    this.dialogRef = null;
  }

  onContinue() {
    this.idleService.stopTimer();
    this.idleTokenService.restoreToken();
  }

  onLogout() {
    this.idleService.stopWatching();
    this.router.navigate(['/auth', 'logout']);
  }

  closeDialog() {
    this.dialogRef?.close();
    this.dialogRef = null;
  }
}
