<div class="row error-content flex-column-reverse flex-lg-row">
  <div class="col-12 col-xl-6 col-lg-8 flex-content-center">
    <img alt="Error logo" class="error-logo" src="../../../../assets/error_1.png" />
  </div>
  <div class="col-12 col-xl-6 col-lg-4 flex-content-center">
    <div class="error-text-content py-5">
      <ng-content></ng-content>
    </div>
  </div>
</div>
