import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { LOCAL_STORAGE, WINDOW } from '@core/injectors';
import { environment } from '@environments/environment';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  bufferTime,
  distinctUntilChanged,
  filter,
  finalize,
  from,
  fromEvent,
  interval,
  map,
  merge,
  of,
  scan,
  switchMap,
  take,
  takeUntil,
  tap,
  timer,
} from 'rxjs';

const DEFAULT_IDLE_TIME = 28 * 60 * 1000; // 28 min
const DEFAULT_TIMEOUT_TIME = 2 * 60; // 2 min

@Injectable()
export class IdleService {
  public readonly STORAGE_KEY: string = 'act';

  private activityEvents$!: Observable<any>;

  private storageChange$ = new Subject<string>();
  private timerStart$ = new Subject<boolean>();
  private idleDetected$ = new Subject<boolean>();
  private timeout$ = new BehaviorSubject<boolean>(false);
  private idle$!: Observable<any>;
  private timer$!: Observable<any>;

  private isTimeout: boolean = false;
  private isInactivityTimer: boolean = false;
  private isIdle: boolean = false;
  private idleSubscription!: Subscription;
  private idleStorageChanged: boolean = false;

  /**
   * Idle value in milliseconds.
   */
  private readonly idleTime = environment.idleTime || DEFAULT_IDLE_TIME;
  /**
   * Timeout value in seconds.
   */
  protected readonly timeoutTime = environment.timeoutTime || DEFAULT_TIMEOUT_TIME;

  private readonly generalTimeoutTime = this.idleTime + this.timeoutTime * 1000;
  /**
   * Idle buffer wait time milliseconds to collect user action
   */
  private readonly idleCheckInterval = 1000;

  private readonly tick = 1000;

  private _dateTimeStart!: Date;

  private set dateTimeStart(value: Date) {
    this._dateTimeStart = value;
    this.storage.setItem(this.STORAGE_KEY, value.toISOString());
  }

  private get dateTimeStart() {
    return this._dateTimeStart;
  }

  constructor(
    private ngZone: NgZone,
    @Inject(WINDOW) private readonly win: Window,
    @Inject(DOCUMENT) private readonly doc: Document,
    @Inject(LOCAL_STORAGE) private readonly storage: Storage,
  ) {
    this.loadTimeFromStorage();
  }

  /**
   * Initialize the idle detection by checking the local storage for the last activity time.
   * If the time difference between now and the last activity time is greater than or equal to
   * the general timeout time, then it means the user is inactive and the timeout observable is
   * triggered.
   */
  loadTimeFromStorage() {
    const activity: string | null = this.storage.getItem(this.STORAGE_KEY);

    if (activity) {
      const currentTime: Date = new Date();
      this._dateTimeStart = new Date(activity);

      if (this.dateTimeStart && currentTime.getTime() - this.dateTimeStart.getTime() >= this.generalTimeoutTime) {
        // Remove the last activity time from the local storage
        this.storage.removeItem(this.STORAGE_KEY);
        // Trigger the timeout observable
        this.timeout$.next(true);
      }
    }
  }

  resetTimer() {
    const activity: string | null = this.storage.getItem(this.STORAGE_KEY);
    if (activity) {
      this._dateTimeStart = new Date(activity);
      this.storageChange$.next(activity);
    }
  }

  /**
   * Start watching for user idle and setup timer and ping.
   */
  startWatching() {
    if (!this.activityEvents$) {
      this.activityEvents$ = merge(
        fromEvent(this.win, 'mousemove'),
        fromEvent(this.win, 'resize'),
        fromEvent(this.doc, 'keydown'),
        this.storageChange$.asObservable().pipe(
          tap(() => {
            this.idleStorageChanged = true;
          }),
        ),
      );
    }

    this.idle$ = from(this.activityEvents$);

    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe();
    }

    // If any of user events is not active for idle-seconds when start timer.
    this.idleSubscription = this.idle$
      .pipe(
        bufferTime(this.idleCheckInterval), // Starting point of detecting of user's inactivity
        filter((activityEvents) => !activityEvents.length && !this.isIdle && !this.isInactivityTimer),
        tap(() => {
          if (this.idleStorageChanged) {
            this._dateTimeStart = new Date();
            this.idleStorageChanged = false;
          } else {
            this.dateTimeStart = new Date();
          }

          this.isIdle = true;
          this.idleDetected$.next(true);
        }),
        switchMap(() =>
          this.ngZone.runOutsideAngular(() =>
            interval(1000).pipe(
              takeUntil(
                merge(
                  this.activityEvents$,
                  timer(this.idleTime).pipe(
                    tap(() => {
                      this.isInactivityTimer = true;
                      this.timerStart$.next(true);
                    }),
                  ),
                ),
              ),
              finalize(() => {
                this.isIdle = false;
                this.idleDetected$.next(false);
              }),
            ),
          ),
        ),
      )
      .subscribe();

    this.setupTimer(this.timeoutTime);
  }

  /**
   * Stop user idle service
   */
  stopWatching() {
    this.stopTimer();
    this.idleSubscription?.unsubscribe();
    this.storage.removeItem(this.STORAGE_KEY);
  }

  stopTimer() {
    this.isInactivityTimer = false;
    this.timerStart$.next(false);
  }

  /**
   * Return observable for timer's countdown number that emits after idle.
   */
  onTimerStart(): Observable<number | null> {
    return this.timerStart$.pipe(
      distinctUntilChanged(),
      switchMap((start) => (start ? this.timer$.pipe(map((t) => this.timeoutTime - t)) : of(null))),
    );
  }

  /**
   * Return observable for timeout is fired.
   */
  onTimeout(): Observable<boolean> {
    return this.timeout$.pipe(
      filter((timeout) => !!timeout),
      tap(() => (this.isTimeout = true)),
      map(() => true),
    );
  }

  /**
   * Setup timer.
   *
   * Counts every seconds and return n+1 and fire timeout for last count.
   * @param timeout Timeout in seconds.
   */
  private setupTimer(timeout: number) {
    this.ngZone.runOutsideAngular(() => {
      this.timer$ = interval(this.tick).pipe(
        take(timeout),
        map(() => 1),
        scan((acc, n) => acc + n),
        tap((count) => {
          const currentTime = new Date();
          if (this.dateTimeStart && currentTime.getTime() - this.dateTimeStart.getTime() >= this.generalTimeoutTime) {
            this.timeout$.next(true);
          } else if (count === timeout) {
            this.timeout$.next(true);
          }
        }),
      );
    });
  }
}
